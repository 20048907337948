export const userType = ["Admin", "General", "Super Admin"];

export const accessLevel = ["Page", "State"];

// export const pageCategory = [
//   "All MP's",
//   "General Secretary",
//   "National",
//   "State",
// ];

export const pageCategory = [
  "MP",
  "National",
  "State",
  "Third Party",
  "National Leader",
  "Morcha",
  "MLA",
  "Leader",
  "District",
  "CM",
  "National Morchas",
];
export const instagramAccountType=['Business']
export const pageSubCategory = ["Party Page", "Morcha Page", "Leader Page" , "All"];

export const platform = [
  {
    id: 1,
    name: "Facebook",
  },
  {
    id: 2,
    name: "Twitter",
  },
  {
    id: 3,
    name: "YouTube",
  },
  {
    id: 4,
    name: "Instagram",
  },
];

export const region = ["National", "State", "District", "AC", "PC"];

export const reportType = [
  {
    label: "Daily",
    value: 1,
  },
  {
    label: "Weekly",
    value: 2,
  },
  {
    label: "Monthly",
    value: 3,
  },
  {
    label: "State",
    value: 4,
  },
  {
    label: "National",
    value: 5,
  },
  {
    label: "General",
    value: 6,
  },
  // {
  //   label: "Others",
  //   value: 'o',
  // },
];

export const reportTypeByFrequency = [
  {
    label: "Daily",
    value: 1,
  },
  {
    label: "Weekly",
    value: 2,
  },
  {
    label: "Monthly",
    value: 3,
  },
  {
    label: "TWD-Daily",
    value: 9,
  },
  {
    label: "TWD-Weekly",
    value: 10,
  },
  {
    label: "TWD-Monthly",
    value: 11,
  },
  {
    label: "On Demand",
    value: 12,
  },
  {
    label: "Daily + On Demand",
    value: 6,
  },
  {
    label: "Weekly + On Demand",
    value: 7,
  },
  {
    label: "Monthly + On Demand",
    value: 8,
  },
  {
    label: "On Demand Daily",
    value: 13,
  },
  {
    label: "On Demand Weekly",
    value: 14,
  },
  {
    label: "On Demand Monthly",
    value: 15,
  },
];

export const reportTypeByFrequencyTagging = [
  {
    label: "Daily",
    value: "d",
  },
  {
    label: "Weekly",
    value: "w",
  },
  {
    label: "Monthly",
    value: "m",
  },
];

export const reportTypesByCategory = [
  {
    label: "General",
    value: 1,
  },
  {
    label: "State",
    value: 2,
  },
  {
    label: "National",
    value: 3,
  },
];

export const reportTypesByFrequencyMapping = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
};

export const reportTypesByCategoryMapping = {
  State: 2,
  National: 3,
  General: 1,
};
export const countryMapping=[
  {
      "id": 50,
      "name": "National"
  },
  {
      "id": 51,
      "name": "India"
},
  {
      "id": 52,
      "name": "Ireland"
  }
]
//   export const reportTypesMapping= [
//   {
//     "Daily": 1,
//    "Weekly": 2,
//    "Monthly": 3,
//    "State": 4,
//    "National": 5,
//    "General":6
//   },

// ];

// export const reportTypes = [
//   {
//     label: "General",
//     value: 1,
//   },
//   {
//     label: "State",
//     value: 2,
//   },
//   {
//     label: "National",
//     value: 3,
//   },
//   // {
//   //   label: "Others",
//   //   value: 'o',
//   // },
// ];

export const PageName = [
  "Page Name1",
  "Page Name2",
  "Page Name3",
  "Page Name4",
];

export const emailDomain = ["@gmail.com", "@tailnode.com"];

export const mappingTable = [
  {
    id: 6,
    name: "National",
  },
  {
    id: 2,
    name: "State",
  },
  {
    id: 3,
    name: "District",
  },
  {
    id: 5,
    name: "PC",
  },
  {
    id: 4,
    name: "AC",
  },
];

export const twitterCategories = [
  {
    key: 1,
    value: "Media Houses",
  },
  {
    key: 2,
    value: "BJP Party",
  },
  {
    key: 3,
    value: "Non-BJP Party",
  },
];

export const tendencies = [
  "भाजपा के पक्ष",
  "भाजपा के खिलाफ",
  "विपक्ष के पक्ष",
  "विपक्ष के खिलाफ",
  "तटस्थ",
 "सरकार के पक्ष",
"सरकार के खिलाफ"
];
export const newsTypes = [
  "Breaking News",
  "Live News",
  "Ticker",
  "Headlines",
  "Ad Breaks",
  "Fast News",
  "Debate"
];
export const newspapers = [
  "Times of India",
  "Economics Times",
  "Navbharat Times",
  "Hindustan Times",
  "Indian Express",
  "Dainik Bhaskar",
  "Hindustan",
  "Dainik Jagran",
  "Amar Ujala",
  "Nai Dunia",
  "Patrika",
  "Eenadu",
  "Enadu",
  "Andhrajyothi",
  "V6 Velugu",
  "Velugu",
  "Disha",
  "Sakshi",
  "Northeast Herald",
  "North East India24",
  "The North-East Affairs",
  "Northeast Today",
  "East Mojo",
  "The Sentinel",
  "The Assam Tribune",
  "Pratidin Time",
  "Guwahati Plus",
  "NEWS LIVE",
  "Etv dhubri",
  "Cross Current",
  "DA Plus",
  "Time 8",
  "NK Tv",
  "E-Pao",
  "Imphal Times",
  "Imphal Free Press",
  "Eastern Chronicle",
  "The Sangai Express",
  "The Frontier Manipur",
  "HY News",
  "Ukhrul Times",
  "Manipur Times",
  "News Update of Tripura",
  "ENEWS TIME",
  "Tripura Net",
  "Arunachal Times",
  "Arunachal 24",
  "Echo of Arunachal",
  "Arunachal Observer",
  "Highland Post",
  "The Meghalayan",
  "The Shillong Times",
  "Nagaland Post",
  "Morung Express",
  "Eastern Mirror",
  "Nagaland Page",
  "Nagaland Tribune",
  "The Mizoram Post",
  "Sikkim Express",
  "Sikkim Chronicle",
  "Voice of Sikkim",
  "Sikkim express",
  "Andhra Jyoti",
  "Gujarat Samchar",
  "Sandesh",
  "Divya Bhaskar",
  "Rajasthan Patrika",
  "Dainik Navajyoti",
  "The Hindu",
  "Ananda Bazar Patrika",
  "Bartaman",
  "Ei Samay",
  "Midday",
  "NavGujarat Samay",
  "Sangad Pratidin",
  "Deccan Chronicle",
  "The Statesmen",
  "Telegraph",
  "Business Standard",
  "Sangad Pratdin",
  "Punjab Kesri"
];
export const pageNumbers = [
  "Main Page",
  "Page 1",
  "Page 2",
  "Page 3",
  "Page 4",
  "Page 5",
  "Page 6",
  "Page 7",
  "Page 8",
  "Page 9",
  "Page 10",
  "Page 11",
  "Page 12",
  "Page 13",
  "Page 14",
  "Page 15",
  "Page 16",
  "Page 17",
  "Page 18",
  "Page 19",
  "Page 20",
  "Page 21",
  "Page 22",
  "Page 23",
  "Page 24",
  "Page 25",
  "Page 26",
  "Page 27",
  "Page 28",
  "Page 29",
  "Page 30",
  "J1",
  "J2",
  "J3",
  "J4",
  "J5",
  "J6",
  "J7",
  "J8",
  "J9",
  "J10"
];

export const articlePosition = [
  "Small Corner",
  "Quarter Page",
  "Small Middle",
  "Half Page",
  "Full Page",
  "Right Hand Corner",
  "Left Hand Corner",
];
export const coverageDurations = [
  "High Coverage",
  "Medium Coverage",
  "Low Coverage",
];
export const columnPosition = [1, 2, 3, 4, 5, 6, 7, 8];

// export const StateList = [
//   {
//     : "उत्तर प्रदेश",
//   },

//   {
//     id: 12,
//     name: "गुजरात",
//   },

//   {
//     id: 36,
//     name: "पश्चिम बंगाल",
//   },

//   {
//     id: 33,
//     name: "त्रिपुरा",
//   },

//   {
//     id: 32,
//     name: "तेलंगाना",
//   },
// ];
export const extraFieldHeading = [
  { id: 401, name: "Pro INDI Alliance" },
  { id: 402, name: "Anti INDI Alliance" },
  { id: 403, name: "Other Parties" },
  { id: 405, name: "Pro NDA" },
  { id: 406, name: "Anti NDA" },
  { id: 407, name: "Pro BJP" },
  { id: 408, name: "Anti BJP" },
  { id: 409, name: "Pro INC" },
  { id: 410, name: "Anti INC" },
  { id: 411, name: "Pro AAP" },
  { id: 412, name: "Anti AAP" },
  { id: 413, name: "Pro TMC" },
  { id: 414, name: "Anti TMC" },
  { id: 415, name: "Neutral" },
  { id: 416, name: "Pro SP" },
  { id: 417, name: "Anti SP" },
  { id: 418, name: "Pro Govt" },
  { id: 419, name: "Anti Govt" },
];
export const stateHindiName = {
  "Andaman and Nicobar Islands": "अंडमान व नोकोबार द्वीप समूह",
  "Andhra Pradesh": "आंध्र प्रदेश",
  "Arunachal Pradesh": "अरुणाचल प्रदेश",
  Assam: "असम",
  Bihar: "बिहार",
  Chandigarh: "चंडीगढ़",
  Chhattisgarh: "छत्तीसगढ",
  "Dadra and Nagar Haveli": "दादरा और नगर हवेली",
  "Daman and Diu": "दमन और दीव",
  Delhi: "दिल्ली",
  Gujarat: "गुजरात",
  Haryana: "हरयाणा",
  "Himachal Pradesh": "हिमाचल प्रदेश",
  "Jammu and Kashmir": "जम्मू और कश्मीर",
  Jharkhand: "झारखंड",
  Karnataka: "कर्नाटक",
  Kerala: "केरल",
  Lakshadweep: "लक्षद्वीप",
  "Madhya Pradesh": "मध्य प्रदेश",
  Maharashtra: "महाराष्ट्र",
  Manipur: "मणिपुर",
  Meghalaya: "मेघालय",
  Mizoram: "मिजोरम",
  Nagaland: "नगालैंड",
  Odisha: "ओडिशा",
  Puducherry: "पुदुचेरी",
  Punjab: "पंजाब",
  Sikkim: "सिक्किम",
  Telangana: "तेलंगाना",
  Tripura: "त्रिपुरा",
  Uttarakhand: "उत्तराखंड",
  "West Bengal": "पश्चिम बंगाल",
  Rajasthan: "राजस्थान",
  Goa: "गोवा",
  "Uttar Pradesh": "उत्तर प्रदेश",
  "Tamil Nadu": "तमिलनाडु",
};

export const columnHeaderFacebookDaily = [
  { id: "position", label: "Rank", align: "left", sortable: true },
  {
    id: "page__page_name",
    label: "Page Name",
    align: "left",
    sortable: true,
  },
  {
    id: "page__political_party__name",
    label: "Party",
    align: "left",
    sortable: true,
  },
  { id: "score", label: "Total Score", align: "center", sortable: true },
  {
    id: "total_posts",
    label: "Total Posts",
    align: "center",
    sortable: true,
  },
  {
    id: "total_shares",
    label: "Total Shares",
    align: "center",
    sortable: true,
  },
  {
    id: "num_posts_in_top",
    label: `No. of Posts in Top 10`,
    align: "center",
    sortable: true,
  },
  {
    id: "max_shares",
    label: "Max Shares of a Post",
    align: "center",
    sortable: true,
  },
  {
    id: "median_shares",
    label: "Median Shares of Post",
    align: "center",
    sortable: true,
  },
  {
    id: "verification_status",
    label: "Verification",
    align: "center",
    sortable: true,
  },
  {
    id: "followers_count",
    label: "Followers",
    align: "center",
    sortable: true,
  },
];
export const columnHeaderFacebookWeekly = [
  { id: "position", label: "Rank", align: "left", sortable: true },
  {
    id: "page__page_name",
    label: "Page Name",
    align: "left",
    sortable: true,
  },
  {
    id: "page__political_party__name",
    label: "Party",
    align: "left",
    sortable: true,
  },
  { id: "score", label: "Total Score", align: "center", sortable: true },
  {
    id: "total_posts",
    label: "Total Posts",
    align: "center",
    sortable: true,
  },
  {
    id: "total_shares",
    label: "Total Shares",
    align: "center",
    sortable: true,
  },
  {
    id: "num_posts_in_top",
    label: `No. of Posts in Top 10`,
    align: "center",
    sortable: true,
  },
  {
    id: "max_shares",
    label: "Max Shares of a Post",
    align: "center",
    sortable: true,
  },
  {
    id: "median_shares",
    label: "Median Shares of Post",
    align: "center",
    sortable: true,
  },
  {
    id: "verification_status",
    label: "Verification",
    align: "center",
    sortable: true,
  },
  {
    id: "followers_count",
    label: "Followers",
    align: "center",
    sortable: true,
  },
  {
    id: "no_of_days_won",
    label: "Days Won",
    align: "center",
    sortable: true,
  },
];
export const columnHeaderFacebookMonthly = [
  { id: "position", label: "Rank", align: "left", sortable: true },
  {
    id: "page__page_name",
    label: "Page Name",
    align: "left",
    sortable: true,
  },
  {
    id: "page__political_party__name",
    label: "Party",
    align: "left",
    sortable: true,
  },
  { id: "score", label: "Total Score", align: "center", sortable: true },
  {
    id: "total_posts",
    label: "Total Posts",
    align: "center",
    sortable: true,
  },
  {
    id: "total_shares",
    label: "Total Shares",
    align: "center",
    sortable: true,
  },
  {
    id: "num_posts_in_top",
    label: `No. of Posts in Top 30`,
    align: "center",
    sortable: true,
  },
  {
    id: "max_shares",
    label: "Max Shares of a Post",
    align: "center",
    sortable: true,
  },
  {
    id: "median_shares",
    label: "Median Shares of Post",
    align: "center",
    sortable: true,
  },
  {
    id: "verification_status",
    label: "Verification",
    align: "center",
    sortable: true,
  },
  {
    id: "followers_count",
    label: "Followers",
    align: "center",
    sortable: true,
  },
  {
    id: "no_of_days_won",
    label: "Days Won",
    align: "center",
    sortable: true,
  },
];

export const columnHeaderYoutubeDaily = [
  { id: "position", label: "Rank", align: "left", sortable: true },
  {
    id: "page__page_name",
    label: "Channel Name",
    align: "left",
    sortable: true,
  },
  {
    id: "page__political_party__name",
    label: "Party",
    align: "left",
    sortable: true,
  },
  { id: "score", label: "Total Score", align: "center", sortable: true },
  {
    id: "total_posts",
    label: "Total Videos",
    align: "center",
    sortable: true,
  },
  {
    id: "total_shares",
    label: "Total Views",
    align: "center",
    sortable: true,
  },
  {
    id: "num_posts_in_top",
    label: `No. of Videos in Top 10`,
    align: "center",
    sortable: true,
  },
  {
    id: "max_shares",
    label: "Max Views of a Video",
    align: "center",
    sortable: true,
  },
  {
    id: "median_shares",
    label: "Median Views of Video",
    align: "center",
    sortable: true,
  },
  // {
  //   id: "verification_status",
  //   label: "Verification",
  //   align: "center",
  //   sortable: true,
  // },
  // {
  //   id: "followers_count",
  //   label: "Followers",
  //   align: "center",
  //   sortable: true,
  // },
];
export const columnHeaderYoutubeWeekly = [
  { id: "position", label: "Rank", align: "left", sortable: true },
  {
    id: "page__page_name",
    label: "Channel Name",
    align: "left",
    sortable: true,
  },
  {
    id: "page__political_party__name",
    label: "Party",
    align: "left",
    sortable: true,
  },
  { id: "score", label: "Total Score", align: "center", sortable: true },
  {
    id: "total_posts",
    label: "Total Videos",
    align: "center",
    sortable: true,
  },
  {
    id: "total_shares",
    label: "Total Views",
    align: "center",
    sortable: true,
  },
  {
    id: "num_posts_in_top",
    label: `No. of Videos in Top 10`,
    align: "center",
    sortable: true,
  },
  {
    id: "max_shares",
    label: "Max Views of a Video",
    align: "center",
    sortable: true,
  },
  {
    id: "median_shares",
    label: "Median Views of Video",
    align: "center",
    sortable: true,
  },
  // {
  //   id: "verification_status",
  //   label: "Verification",
  //   align: "center",
  //   sortable: true,
  // },
  // {
  //   id: "followers_count",
  //   label: "Followers",
  //   align: "center",
  //   sortable: true,
  // },
  {
    id: "no_of_days_won",
    label: "Days Won",
    align: "center",
    sortable: true,
  },
];
export const columnHeaderYoutubeMonthly = [
  { id: "position", label: "Rank", align: "left", sortable: true },
  {
    id: "page__page_name",
    label: "Channel Name",
    align: "left",
    sortable: true,
  },
  {
    id: "page__political_party__name",
    label: "Party",
    align: "left",
    sortable: true,
  },
  { id: "score", label: "Total Score", align: "center", sortable: true },
  {
    id: "total_posts",
    label: "Total Videos",
    align: "center",
    sortable: true,
  },
  {
    id: "total_shares",
    label: "Total Views",
    align: "center",
    sortable: true,
  },
  {
    id: "num_posts_in_top",
    label: `No. of Videos in Top 30`,
    align: "center",
    sortable: true,
  },
  {
    id: "max_shares",
    label: "Max Views of a Video",
    align: "center",
    sortable: true,
  },
  {
    id: "median_shares",
    label: "Median Views of Video",
    align: "center",
    sortable: true,
  },
  // {
  //   id: "verification_status",
  //   label: "Verification",
  //   align: "center",
  //   sortable: true,
  // },
  // {
  //   id: "followers_count",
  //   label: "Followers",
  //   align: "center",
  //   sortable: true,
  // },
  {
    id: "no_of_days_won",
    label: "Days Won",
    align: "center",
    sortable: true,
  },
];