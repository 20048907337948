import React, { useEffect, useRef, useState } from "react";
import { Chart, LinearScale, BarController, BarElement, LineController, LineElement, PointElement, CategoryScale, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { toPng } from "html-to-image";

Chart.register(LinearScale, BarController, BarElement, LineController, LineElement, PointElement, CategoryScale, Tooltip, Legend, Title, ChartDataLabels);

const colors = ["#0FC6C2", "#009DFF", "#F7BA1E", "#ff8042", "#00C49F"];
const darkenColors=['#094FC3','#05908D','#CD9400'];
const partyColors = {
  "Fianna Fáil": "#66bb66ff",
  "Sinn Féin": "#316760ff",
  "Fine Gael": "#6699ffff",
  "Green Party": "#21ad6fff",
  "Labour": "#cc0100ff",
  "Soc Dems": "#742f8bff",
  "Aontú": "#44532aff",
  "People Before Profit":"#8e2420ff",
  "Independent":"#C6D4D6"
};

const darkenColor = (color, amount) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
};

const mockCampaignSpendData = [
  { start: '2023-01-01', end: '2023-01-07', page_name: 'Uttar Pradesh', campaign_spend: 1000 },
  { start: '2023-01-01', end: '2023-01-07', page_name: 'Delhi', campaign_spend: 1500 },
  { start: '2023-01-08', end: '2023-01-14', page_name: 'Uttar Pradesh', campaign_spend: 1200 },
  { start: '2023-01-08', end: '2023-01-14', page_name: 'Delhi', campaign_spend: 1800 },
  { start: '2023-01-15', end: '2023-01-21', page_name: 'Uttar Pradesh', campaign_spend: 900 },
  { start: '2023-01-15', end: '2023-01-21', page_name: 'Delhi', campaign_spend: 2000 },
  { start: '2023-01-22', end: '2023-01-28', page_name: 'Uttar Pradesh', campaign_spend: 1400 },
  { start: '2023-01-22', end: '2023-01-28', page_name: 'Delhi', campaign_spend: 1700 },
];

const DynamicChart = ({ data, type, tabValue, isIreland = false, setChartImage }) => {
  const [xAxis, setXaxis] = useState("Date");
  const [chart, setChart] = useState(null);
  const [chartInstance, setChartInstance] = useState(null);

  const weekCountRef = useRef(0);
  const formatIrishDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${day} ${monthNames[date.getMonth()]}`;
  };
  const formatDate = (dateString) => {
    const [start, end] = dateString.split(',');
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : null;
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    if (endDate) {
      if (isIreland) {
        if(start === end){
          return `${formatIrishDate(start)}`;

        }else{
          return `${formatIrishDate(start)} - ${formatIrishDate(end)}`;

        }
      }  else {
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays == 0) {
          setXaxis("Dates");
          return startDate.toLocaleDateString();
        } else if (diffDays > 0 && diffDays <= 6) {
          setXaxis("Weeks");
          weekCountRef.current += 1;
          return `Week ${weekCountRef.current}`;
        }
      }
    }
    setXaxis("Months");
    return `${monthNames[startDate.getMonth()]}`;
  };
  const chartRef = useRef(null);
  const [hasBeenDrawn, setHasBeenDrawn] = useState(false);
  const assignColors = (chartData) => {
    // Create a map to track which party colors have been used
    const usedPartyColors = new Map();
    // Create a map to store assigned colors for each page
    const pageColorMap = new Map();
    // Track which colors from the default array have been used
    const usedColorIndices = new Set();
    
    // First pass: assign official party colors to one page per party
    chartData.forEach(item => {
      const { page_name, party_name } = item;
      
      if (!pageColorMap.has(page_name) && party_name && partyColors[party_name]) {
        if (!usedPartyColors.has(party_name)) {
          // Assign official party color to this page
          pageColorMap.set(page_name, partyColors[party_name]);
          usedPartyColors.set(party_name, true);
        }
      }
    });

    // Second pass: assign random colors to remaining pages
    chartData.forEach(item => {
      const { page_name } = item;
      
      if (!pageColorMap.has(page_name)) {
        // Find an unused color from the colors array
        let colorIndex;
        do {
          colorIndex = Math.floor(Math.random() * colors.length);
        } while (usedColorIndices.has(colorIndex) && usedColorIndices.size < colors.length);
        
        // If all colors are used, start reusing them
        if (usedColorIndices.size < colors.length) {
          usedColorIndices.add(colorIndex);
        }
        
        pageColorMap.set(page_name, colors[colorIndex]);
      }
    });

    return pageColorMap;
  };
  useEffect(() => {
    if (hasBeenDrawn) {
      toPng(chartRef.current, { cacheBust: true })
        .then((dataUrl) => {
          setChartImage(dataUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [hasBeenDrawn]);
  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      if (chart) {
        chart.destroy();
      }
      const chartData = type === "campaignSpend" ? mockCampaignSpendData : data;
      const dates = [...new Set(chartData.map((item) => item.start + (item.end ? ',' + item.end : '')))].sort();
      const pages = [...new Set(chartData.map((item) => item.page_name))];
      const pageColorMap = assignColors(chartData);
      const datasets = pages.flatMap((page) => {
        const baseColor = pageColorMap.get(page);
        
        if (type === "campaignSpend") {
          return [{
            type: "line",
            label: `${page} Campaign Spend`,
            data: dates.map((dateRange) => {
              const [start, end] = dateRange.split(',');
              const item = chartData.find(
                (d) => d.start === start && (!end || d.end === end) && d.page_name === page
              );
              return item ? item.campaign_spend : 0;
            }),
            borderColor: baseColor,
            backgroundColor: baseColor,
            yAxisID: "y",
            tension: isIreland ? 0.4 : 0,
          }];
        } else {
          return [
            {
              type: "bar",
              label: `${page} ${(tabValue === "facebook" || tabValue === "instagram")? 'Posts' : 'Videos'}`,
              data: dates.map((dateRange) => {
                const [start, end] = dateRange.split(',');
                const item = chartData.find(
                  (d) => d.start === start && (!end || d.end === end) && d.page_name === page
                );
                return item ? item.total_posts : 0;
              }),
              backgroundColor: baseColor,
              order: 2,
              yAxisID: "y",
              datalabels: {
                anchor: 'end',
                align: 'start',
                offset: -15,
                display: isIreland,
              },
            },
            {
              type: "line",
              label: `${page} ${tabValue === "facebook" ? "Shares" :tabValue === "instagram"?"Likes": "Views"}`,
              data: dates.map((dateRange) => {
                const [start, end] = dateRange.split(',');
                const item = chartData.find(
                  (d) => d.start === start && (!end || d.end === end) && d.page_name === page
                );
                return item ? item.total_shares : 0;
              }),
              borderColor: darkenColor(baseColor, 40),
              backgroundColor: darkenColor(baseColor, 40),
              pointBackgroundColor: darkenColor(baseColor, 40),
              pointBorderColor: darkenColor(baseColor, 40),
              fill: false,
              order: 1,
              yAxisID: "y1",
              pointStyle: 'triangle',
              pointRadius: 6,
              pointHoverRadius: 8,
              tension: isIreland ? 0.4 : 0,
              datalabels: {
                anchor: 'end',
                align: 'start',
                offset: 3,
                display: false,
                color:'grey'
              },
            },
          ];
        }
      });

      const newChart = new Chart(ctx, {
        type: type === "campaignSpend" ? "line" : "bar",
        data: {
          labels: dates.map(formatDate),
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            onComplete: () => {
              // This callback is fired when the animation of the chart is complete
              setHasBeenDrawn(true);
            }
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          layout: {
            padding: 0,
          },
          elements: {
            point: {
              radius: 4,
            },
            line: {
              tension: isIreland ? 0.4 : 0,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: xAxis,
              },
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              display: true,
              position: "left",
              title: {
                display: true,
                text: type === "campaignSpend" ? "Campaign Spend" : (tabValue === "facebook" || tabValue === "instagram") ? "Total Posts" : "Total Videos",
              },
              grid: {
                display: false,
              },
              ticks: {
                // Ensuring whole numbers
                stepSize: 1,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
            ...(type !== "campaignSpend" && {
              y1: {
                type: "linear",
                display: true,
                position: "right",
                title: {
                  display: true,
                  text: tabValue === "facebook" ? "Total Shares" :tabValue === "instagram"?"Total Likes": "Total Views",
                },
                grid: {
                  drawOnChartArea: false,
                },
                
              },
            }),
          },
          plugins: {
            datalabels: {
              display: isIreland,
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: (value) => {
                return value.toLocaleString();
              }
            },
            tooltip: {
              mode: "index",
              callbacks: {
                title: function (context) {
                  const dataIndex = context[0].dataIndex;
                  const originalDate = dates[dataIndex];
                  const [start, end] = originalDate.split(',');
                  
                  if (end && start !== end) {
                    return `Date: ${new Date(start).toLocaleDateString()} to ${new Date(end).toLocaleDateString()}`;
                  }
                  return "Date: " + new Date(start).toLocaleDateString();
                },
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                },
              },
            },
            legend: {
              position: 'bottom',
              display: true,
              labels: {
                usePointStyle: true,
                boxWidth: 150,  // Adjust width as needed
                font: {
                  size: 12,    // Adjust font size if needed
                },
                filter: function(legendItem, data) {
                  if (isIreland) {
                    return !legendItem.text.includes("Shares") && !legendItem.text.includes("Views") && !legendItem.text.includes("Likes");
                  }
                  return true;
                },
                generateLabels: function(chart) {
                  const original = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                  if (isIreland) {
                    const spacedLabels = [];
                    original.forEach((label, index) => {
                      // Modify the label text by removing "Posts" if it exists
                      const modifiedLabel = { 
                        ...label, 
                        text: label.text.includes("Posts") ? label.text.replace("Posts", "").trim() : label.text 
                      };
                
                      // Add label to array if it doesn't contain "Shares", "Views", or "Likes"
                      if (!label.text.includes("Shares") && !label.text.includes("Views") && !label.text.includes("Likes")) {
                        spacedLabels.push(modifiedLabel);
                        
                        // Add an empty label for spacing
                        if (index < original.length - 1) {
                          spacedLabels.push({
                            text: '   ', // Empty space
                            fillStyle: 'transparent', // Make it invisible
                            strokeStyle: 'transparent',
                            hidden: true,
                          });
                        }
                      }
                    });
                    return spacedLabels;
                  }
                  return original;
                },
                
                pointStyle: (context) => {
                  return (type === "campaignSpend") ? 'rect' : (context.datasetIndex % 2 === 0 ? 'triangle' : 'rect');
                },
              },
            },
            
          },
       
          ...(type !== "campaignSpend" ?isIreland?{barThickness:25}: { barThickness: 30 } : { barThickness: 40 }),
        },
      });

      setChart(newChart);

    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, type, isIreland]);

  return (
    <div style={{ width: "100%", height: type === "totalPostAndShare" ? "400px" : "550px", position: "relative" }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default DynamicChart;